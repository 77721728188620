import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout/Layout";
import Icons from "../Assets/icons";
import PaginationComponent from "../Components/common/Pagination";
import { Container, Table } from "react-bootstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { fullName } from "../utils/commonFunction";
import { useDispatch, useSelector } from "react-redux";
import { getOrderList } from "../Redux/Actions/orderAction";
import NotificationComp from "../Components/common/NotificationComp";
import { setLoader } from "../Redux/Reducers/loaderSlice";

const PastOrders = () => {
  const dispatch = useDispatch();
  const { orderList } = useSelector((state) => state?.orderAuthData);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    const fetchData = async (options = {}) => {
      dispatch(setLoader(true));
      try {
        const { page = currentPage, limitValue = limit } = options;
        let apiREs = await dispatch(
          getOrderList({
            status: 5,
            pagination: page - 1,
            limit: limitValue,
          })
        ).unwrap();
        console.log("apire", apiREs)
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        dispatch(setLoader(false));
      }
    };

    fetchData();
  }, [currentPage, limit, dispatch]);

  return (
    <>
      <Layout>
        <div className="right-top">
          <div className="heading-top">
            <div className="common-head">
              <h2>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  viewBox="0 0 35 35"
                  fill="none"
                >
                  <path
                    d="M16.2684 34.9998C15.6632 34.9112 15.0542 34.8424 14.4532 34.731C9.53214 33.8177 5.68669 31.246 2.89604 27.1189C0.959998 24.2553 0.0152764 21.0639 1.54324e-05 17.6034C-0.00290689 16.9341 0.409465 16.4838 1.01601 16.4808C1.62531 16.4778 2.02811 16.9159 2.04986 17.5893C2.23267 23.2122 4.66956 27.5765 9.39869 30.6292C11.2979 31.8552 13.4024 32.549 15.6494 32.8254C21.9963 33.6059 28.0212 30.3666 31.001 24.9821C32.4636 22.339 33.1453 19.5015 32.8893 16.4849C32.3935 10.6407 29.4877 6.36235 24.3041 3.66312C21.8746 2.39804 19.2476 1.92551 16.5056 2.10514C13.8234 2.28104 11.3704 3.1221 9.12415 4.5858C9.0506 4.63367 8.98144 4.68851 8.85887 4.77712C8.97966 4.79058 9.04005 4.79561 9.09996 4.80438C9.60682 4.87756 9.98656 5.24008 10.0398 5.70045C10.097 6.19473 9.83021 6.64016 9.3266 6.81444C8.28544 7.17469 7.23714 7.51449 6.19176 7.86256C5.81446 7.98816 5.43667 8.11197 5.05969 8.23838C4.63368 8.38134 4.24631 8.32211 3.92859 7.99433C3.62223 7.67854 3.56606 7.30386 3.70617 6.8886C4.17828 5.48949 4.64245 4.08762 5.11586 2.68883C5.32318 2.07626 5.85521 1.78011 6.41905 1.94709C6.96699 2.10936 7.2464 2.67163 7.08794 3.29232C7.07203 3.35447 7.05872 3.41711 7.03745 3.50944C7.61006 3.13784 8.14111 2.76835 8.69506 2.43747C11.7112 0.636414 14.9836 -0.159693 18.4848 0.0264329C26.3918 0.446394 33.1196 6.30053 34.6608 14.0894C34.7863 14.7236 34.8437 15.3711 34.9355 16.012C34.9482 16.1006 34.9781 16.187 35 16.2743C35 17.0944 35 17.9145 35 18.7346C34.9781 18.8215 34.943 18.9075 34.9359 18.9954C34.7316 21.5251 34.0207 23.9069 32.7344 26.0909C29.9686 30.7871 25.9251 33.7132 20.5423 34.731C19.9424 34.8444 19.3339 34.9114 18.7293 35C17.9091 34.9998 17.0888 34.9998 16.2684 34.9998Z"
                    fill="#A72A2F"
                  />
                  <path
                    d="M6.26286 18.5307C6.7254 18.5307 7.14394 18.5307 7.56248 18.5307C8.12064 18.5307 8.67897 18.5377 9.23697 18.5286C9.8591 18.5185 10.3312 18.0577 10.3195 17.4882C10.308 16.9271 9.84725 16.4867 9.23973 16.4804C8.38544 16.4714 7.53098 16.4779 6.67669 16.4779C6.55395 16.4779 6.43122 16.4779 6.30783 16.4779C6.57685 11.4433 10.9306 6.69876 16.4721 6.30103C16.4721 6.41316 16.4721 6.52221 16.4721 6.63126C16.4721 7.4853 16.4689 8.3395 16.4733 9.19353C16.4765 9.84847 16.9128 10.3264 17.4971 10.3277C18.0817 10.329 18.521 9.85171 18.5243 9.19694C18.5291 8.24229 18.5256 7.28749 18.5256 6.27783C21.0722 6.52529 23.2685 7.48465 25.1225 9.19126C27.2417 11.1419 28.4246 13.5702 28.7342 16.4779C28.2952 16.4779 27.8996 16.4779 27.5039 16.4779C26.923 16.4779 26.3418 16.4713 25.7611 16.4799C25.1402 16.4891 24.6665 16.9511 24.6779 17.5191C24.6892 18.0802 25.1495 18.5214 25.757 18.5281C26.6113 18.5375 27.4658 18.5307 28.32 18.5307C28.4425 18.5307 28.565 18.5307 28.6889 18.5307C28.4244 23.524 24.1059 28.3073 18.5256 28.7074C18.5256 28.5839 18.5256 28.4634 18.5256 28.343C18.5256 27.5003 18.529 26.6576 18.5244 25.8149C18.5209 25.1587 18.0858 24.6824 17.5006 24.681C16.9168 24.6795 16.4765 25.1582 16.4734 25.8112C16.4691 26.766 16.4723 27.7208 16.4723 28.73C13.9268 28.4796 11.7291 27.5212 9.87485 25.8157C7.75551 23.8664 6.57149 21.4386 6.26286 18.5307ZM17.4698 16.0982C17.3631 15.964 17.2945 15.8581 17.2071 15.7706C16.1221 14.6824 15.0362 13.5952 13.9466 12.5115C13.5776 12.1446 13.1414 12.0497 12.7355 12.2301C12.0572 12.5315 11.927 13.3682 12.4871 13.9324C13.8583 15.3138 15.2381 16.6868 16.6152 18.0624C17.2329 18.6796 17.7612 18.6834 18.3751 18.071C19.7289 16.7204 21.0808 15.368 22.4327 14.0155C22.5131 13.9351 22.596 13.8546 22.6611 13.7625C22.9064 13.4147 22.9313 13.0391 22.7374 12.6675C22.5464 12.3012 22.2117 12.092 21.8117 12.1646C21.5307 12.2155 21.2238 12.3622 21.0199 12.5602C19.8442 13.7011 18.6959 14.87 17.4698 16.0982Z"
                    fill="#A72A2F"
                  />
                </svg>
                Past Orders
              </h2>
            </div>
            <div className="common-icon-side">
              <NotificationComp />
            </div>
          </div>
        </div>
        <div className="commn-table">
          <Container fluid className="px-0">
            <div class="table-responsive position-relative">
              <Table size="sm" className="table-cmn mb-0 ">
                <thead>
                  <tr>
                    <th>Order No</th>
                    <th>Date</th>
                    <th>Customer name</th>
                    <th>Address</th>
                    <th>Quantity</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(orderList?.data) &&
                  orderList?.data?.length > 0 ? (
                    orderList?.data?.map((res, index) => {
                      return (
                        <tr key={res?._id}>
                          <td>{res?.order_id?.order_no || "N/A"}</td>
                          <td className="no-break-data">
                            {moment(res?.createdAt).format("DD/MM/YYYY")}
                          </td>
                          <td>
                            {fullName(
                              res?.user_id?.first_name,
                              res?.user_id?.last_name
                            )}
                          </td>
                          <td>
                            <p className="table-address">
                              {" "}
                              {res?.address_id?.full_address || "N/A"}
                            </p>
                          </td>
                          <td>{res?.quantity || "0"}</td>
                          <td>${res?.total_amount || "0"}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <div className="not-found-data">
                      <p className="text-center text-muted">
                        No Data Available
                      </p>
                    </div>
                  )}
                </tbody>
              </Table>
            </div>
            {/* Pagination */}
            <div className="d-flex justify-content-center pagination-set mt-3">
              <PaginationComponent
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalData={orderList?.count || 0}
                limit={limit}
                onPageChange={handlePageChange}
              />
            </div>
          </Container>
        </div>
      </Layout>
    </>
  );
};

export default PastOrders;
