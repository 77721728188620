import React, { useEffect } from 'react'
import Icons from '../../Assets/icons'
import { Dropdown } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationListAction } from '../../Redux/Actions/auth';
import moment from 'moment';

const NotificationComp = () => {
    const dispatch = useDispatch();
    const { notifyList } = useSelector((state) => state?.userAuthData)


    const getApiCall = () => {
        let apiRes = dispatch(getNotificationListAction());
        console.log("apiRes", apiRes)
    }

    useEffect(() => {
        getApiCall();
    }, [])

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {Icons.bellIcon}
                </Dropdown.Toggle>

                <Dropdown.Menu className={`${notifyList?.length > 0 && `height-set-notify`}`}>
                    {
                        Array.isArray(notifyList) && notifyList?.length > 0 ?
                            notifyList?.map((res) => {
                                return <>
                                    <Dropdown.Item>
                                        <div className="notifi-pop">
                                            <div className="d-flex justify-content-between  mb-2 gap-2 flex-column-reverse">
                                                <h2>{res?.title ?? 'N/A'}</h2>
                                                <p>{res?.createdAt && moment(res?.createdAt).format('hh:mm:a')} | {res?.createdAt ? moment(res?.createdAt).format('DD/MM/YYYY') : 'N/A'}</p>
                                            </div>
                                            <p>
                                                {res?.description ?? 'N/A'}
                                            </p>
                                        </div>
                                    </Dropdown.Item>
                                </>
                            }) :
                            <p className="text-center">
                                No Data Available
                            </p>
                    }
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default NotificationComp