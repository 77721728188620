import React, { useState } from 'react'
import PaginationComponent from './Pagination';
import { Container, Form, Table } from 'react-bootstrap';
import { fullName } from '../../utils/commonFunction';
import { Link } from 'react-router-dom';
import moment from 'moment';
import DeliveryPickupComp from './DeliveryPickupComp';
import { useDispatch } from 'react-redux';
import { getOrderList, orderPickedUpAction } from '../../Redux/Actions/orderAction';
import toast from 'react-hot-toast';

const ManageOrderList = ({ orderList, handleConfirm, orderStatus, deliveryType, handlePageChange, currentPage, limit, setCurrentPage }) => {
    console.log("currentPage", currentPage, "limit", limit)
    const dispatch = useDispatch();

    const handleFilterStatus = async (id, status) => {
        const value = { id, status: Number(status) };
        let res = await dispatch(orderPickedUpAction(value));
        if (res?.payload?.status == 200) {
            toast.success(res?.payload?.message);
            dispatch(getOrderList({ status: orderStatus, type: deliveryType }));
        } else {
            toast.error(res?.payload?.message);
        }
    }

    return (
        <>
            <div>
                <Container fluid className="px-0">
                    <div class="table-responsive position-relative">
                        <Table size="sm" className="table-cmn mb-0 ">
                            <thead>
                                <tr>
                                    <th>Order No</th>
                                    <th>Date</th>
                                    <th>Customer name</th>
                                    {
                                        deliveryType == 1 &&
                                        <th>Address</th>
                                    }
                                    <th>Quantity</th>
                                    <th>Amount</th>
                                    {
                                        orderStatus != 3 &&
                                        <th>Payment Status</th>
                                    }
                                    {
                                        (orderStatus == 2 && deliveryType == 1) &&
                                        <th>Delivery Time</th>
                                    }
                                    {
                                        orderStatus != 1 &&
                                        <th>Status</th>
                                    }
                                    {
                                        (orderStatus == 1 || (orderStatus == 2 && deliveryType == 2)) &&
                                        <th>Action</th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(orderList?.data) &&
                                    orderList?.data?.length > 0 ? (
                                    orderList?.data?.map((res, index) => {
                                        return (
                                            <tr key={res?._id}>
                                                <td>
                                                    {res?.order_id?.order_no || 'N/A'}
                                                </td>
                                                <td className='no-break-data'>
                                                    <Link
                                                        to={`/ManageOrdersDetail/${res?.order_id?._id}?status=${orderStatus}&type=${deliveryType}`}
                                                    >
                                                        {moment(res?.createdAt).format('DD/MM/YYYY')}
                                                    </Link>
                                                </td>
                                                <td>
                                                    <Link
                                                        to={`/ManageOrdersDetail/${res?.order_id?._id}?status=${orderStatus}&type=${deliveryType}`}
                                                    >
                                                        {fullName(
                                                            res?.user_id?.first_name,
                                                            res?.user_id?.last_name
                                                        )}
                                                    </Link>
                                                </td>
                                                {
                                                    deliveryType == 1 &&
                                                    <td>
                                                        <Link
                                                            to={`/ManageOrdersDetail/${res?.order_id?._id}?status=${orderStatus}&type=${deliveryType}`}
                                                        >
                                                            <p className='table-address'>{res?.address_id?.full_address || 'N/A'}</p>

                                                        </Link>
                                                    </td>
                                                }

                                                <td>
                                                    {res?.quantity || '0'}
                                                </td>
                                                <td>
                                                    ${res?.total_amount || '0'}
                                                </td>
                                                {
                                                    orderStatus != 3 &&
                                                    <td >{res?.order_id?.payment_status == 1 ? `Paid` : res?.order_id?.payment_status == 2 ? `Failed` : `Pending`}</td>
                                                }
                                                {
                                                    (orderStatus == 2 && deliveryType == 1) &&
                                                    <td className='no-break-data'>
                                                        <DeliveryPickupComp time={res?.order_id?.selected_date} />
                                                    </td>
                                                }
                                                {
                                                    orderStatus != 1 &&
                                                    <td >
                                                        <p className={orderStatus == 3 && 'text-danger'}>
                                                            {orderStatus == 2 ? res?.order_id?.partner_assigned == 1 ? `Partner Assigned` : `${res?.order_id?.type === 1 ? `Delivery Pending` : `Takeaway Pending`}` : orderStatus == 3 ? `Declined` : 'N/A'}
                                                        </p>
                                                    </td>
                                                }
                                                {
                                                    orderStatus == 1 &&
                                                    <>
                                                        <td className='no-break-data'>
                                                            <div className="order-actn-btns">
                                                                <button type="button" onClick={() => handleConfirm("accept", res)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                                                        <path d="M0 6C0 2.68629 2.68629 0 6 0H24C27.3137 0 30 2.68629 30 6V24C30 27.3137 27.3137 30 24 30H6C2.68629 30 0 27.3137 0 24V6Z" fill="#209920" />
                                                                        <path d="M21.8439 11.6521C21.7324 11.8285 21.5835 11.9804 21.4304 12.1321C18.9182 14.6222 16.409 17.1153 13.9007 19.6093C13.6322 19.8762 13.3246 20.0285 12.9368 19.9956C12.6558 19.9717 12.4233 19.8485 12.2277 19.6545C10.9349 18.3724 9.63906 17.0931 8.35343 15.8039C7.77608 15.2248 7.92816 14.3494 8.64864 13.9991C9.07625 13.7912 9.57417 13.8942 9.95734 14.2742C10.9439 15.2525 11.9295 16.2319 12.9145 17.2118C12.9529 17.25 12.9818 17.2974 13.0212 17.3483C13.0814 17.2917 13.1206 17.2568 13.1578 17.2199C15.4518 14.9418 17.7461 12.6641 20.0389 10.3848C20.2885 10.1366 20.5739 9.98557 20.9365 10.0011C21.416 10.0216 21.8154 10.3286 21.9529 10.7865C22.0407 11.0774 22.0064 11.3949 21.8439 11.6521Z" fill="white" />
                                                                    </svg>
                                                                </button>
                                                                <button type="button" onClick={() => handleConfirm("reject", res)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                                                        <path d="M10 18.9287C10.0668 18.613 10.2803 18.3997 10.4959 18.1828C11.5181 17.1548 12.536 16.1223 13.5564 15.0924C13.5858 15.0628 13.6264 15.0445 13.6617 15.0209C13.6605 15.0051 13.6593 14.9893 13.6581 14.9736C13.6258 14.9514 13.589 14.9338 13.5619 14.9065C12.4887 13.823 11.4165 12.7385 10.3439 11.6543C10.0714 11.3789 9.97802 11.0508 10.0908 10.6792C10.2036 10.3074 10.4637 10.0815 10.8415 10.0155C11.1552 9.96066 11.4343 10.0519 11.6627 10.2815C12.7294 11.353 13.7955 12.4251 14.8615 13.4973C14.9029 13.539 14.9411 13.584 14.9903 13.6378C15.028 13.601 15.0576 13.5731 15.0862 13.5441C16.1495 12.4692 17.2119 11.3933 18.2762 10.3195C18.7969 9.79417 19.6523 9.99302 19.866 10.6916C19.9757 11.05 19.9035 11.3767 19.6383 11.6456C18.5674 12.7314 17.4941 13.8149 16.4213 14.8989C16.3963 14.9242 16.3665 14.9447 16.3246 14.9791C16.3767 15.0268 16.4161 15.06 16.4523 15.0964C17.5373 16.1866 18.6219 17.2772 19.7068 18.3675C19.9117 18.5735 20.0081 18.8212 19.9995 19.1136C19.9854 19.5869 19.5858 19.9814 19.1169 19.9965C18.767 20.0077 18.4832 19.89 18.2351 19.6369C17.1859 18.5664 16.129 17.5036 15.0757 16.4371C15.0485 16.4096 15.0308 16.3725 15.0087 16.3399C14.9943 16.3395 14.9799 16.339 14.9655 16.3386C14.9437 16.3716 14.9266 16.4093 14.8994 16.4369C13.8231 17.5267 12.746 18.6155 11.6688 19.7044C11.345 20.0319 10.7894 20.0959 10.3986 19.853C10.1948 19.7264 10.0861 19.5344 10.0236 19.3091C10.0159 19.281 10.0079 19.253 10 19.2249C10 19.1261 10 19.0274 10 18.9287Z" fill="#A72A2F" />
                                                                        <rect x="0.5" y="0.5" width="29" height="29" rx="5.5" stroke="#A72A2F" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </>

                                                }
                                                {
                                                    (orderStatus == 2 && deliveryType == 2) &&
                                                    <td className='no-break-data'>
                                                        <div className='select-box'>
                                                            <div className="order-card-action-main">
                                                                {
                                                                    res?.delivery_status == 2 ?
                                                                        <p className="form-control w-100" >Received by customer</p>
                                                                        :
                                                                        <Form.Select
                                                                            aria-label="Default select example"
                                                                            value={res?.delivery_status}
                                                                            onChange={(e) =>
                                                                                handleFilterStatus(
                                                                                    res?._id,
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                        >
                                                                            {/* <option>
                                                                                Select
                                                                            </option> */}
                                                                            <option value={"1"} disabled={res?.delivery_status}>Preparing order</option>
                                                                            <option value={"2"}>Received by customer</option>
                                                                        </Form.Select>
                                                                }

                                                            </div>
                                                        </div>
                                                    </td>
                                                }
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <div className="not-found-data">
                                        <p className='text-center text-muted'>No Data Available</p>
                                    </div>
                                )}
                            </tbody>
                        </Table>
                    </div>
                    {/* Pagination */}
                    <div className="d-flex justify-content-center pagination-set mt-4">
                        <PaginationComponent
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalData={orderList?.count || 0}
                            limit={limit || 10}
                            onPageChange={handlePageChange}
                        />
                    </div>
                </Container >
            </div >
        </>
    )
}

export default ManageOrderList