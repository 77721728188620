import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const token = window.localStorage.getItem("token");

const PublicRoute = () => {
    return !token ? (
        <Outlet />
    ) : (
        <Navigate to="/Dashboard" replace />
    );
};

export default PublicRoute;