import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoader } from "../Redux/Reducers/loaderSlice";

const useCommonFetch = (apiAction) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoader(true));
      try {
        await dispatch(apiAction()).unwrap();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        dispatch(setLoader(false));
      }
    };

    fetchData();
  }, [apiAction, dispatch]);
};

export default useCommonFetch;
