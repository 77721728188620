import React, { useCallback, useEffect, useMemo, useState } from "react";
import Layout from "../Components/Layout/Layout";
import Icons from "../Assets/icons";
import PaginationComponent from "../Components/common/Pagination";
import { Container, Table } from "react-bootstrap";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { downloadURL, fullName, getOrderStatus } from "../utils/commonFunction";
import { useDispatch, useSelector } from "react-redux";
import {
  getDownloadInvoiceUrl,
  getInvoiceList,
} from "../Redux/Actions/orderAction";
import AddBankShopDetails from "../Modal/AddBankShopDetails";
import WithdrawAmount from "../Modal/WithdrawAmount";
import NotificationComp from "../Components/common/NotificationComp";
import toast from "react-hot-toast";
import { setLoader } from "../Redux/Reducers/loaderSlice";

const Invoice = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { invoiceList } = useSelector((state) => state?.orderAuthData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenWithdraw, setIsModalOpenWithdraw] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
  }, []);

  const getApiCall = useCallback(
    (options = {}) => {
      const { page = currentPage } = options;
      dispatch(getInvoiceList({ pagination: page - 1, limit: 10 })).then(
        (res) => {
          if (res?.payload?.status === 200) {
            dispatch(setLoader(false));
          }
        }
      );
    },
    [currentPage, dispatch]
  );

  const downloadInvoice = useCallback(async () => {
    let apiRes = await dispatch(getDownloadInvoiceUrl());
    if (apiRes?.payload?.status == 200) {
      downloadURL(apiRes?.payload?.url);
    } else {
      toast.error(apiRes?.payload?.message);
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(setLoader(true));
    getApiCall();
  }, [getApiCall]);

  const memoizedRows = useMemo(() => {
    if (!Array.isArray(invoiceList?.data) || invoiceList?.data?.length === 0) {
      return (
        <div className="not-found-data">
          <p className="text-center text-muted">No Data Available</p>
        </div>
      );
    }

    return invoiceList.data.map((res) => (
      <tr key={res?._id}>
        <td>{res?.order_no || "N/A"}</td>
        <td className="no-break-data">
          {res?.selected_date
            ? moment(res?.selected_date).format("DD/MM/YYYY")
            : "N/A"}
        </td>
        <td>
          {fullName(res?.customer_id?.first_name, res?.customer_id?.last_name)}
        </td>
        <td>
          {res?.address_id?.full_address?.length > 40
            ? `${res?.address_id?.full_address.slice(0, 40)}...`
            : res?.address_id?.full_address || "N/A"}
        </td>
        <td>{res?.total_items || "N/A"}</td>
        <td>${res?.paid_amount || "0"}</td>
        <td>
          {res?.payment_status === 1
            ? "Success"
            : res?.payment_status === 0
            ? "Failed"
            : "N/A"}
        </td>
        <td>{getOrderStatus(res?.order_status)}</td>
        <td>
          <div
            className="with-icon-td align-items-center"
            onClick={downloadInvoice}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              {" "}
              <path
                d="M0 12.9615C0.0143303 12.924 0.0298548 12.8868 0.0429207 12.8489C0.166976 12.4874 0.489267 12.252 0.870706 12.2519C4.95519 12.2513 9.03968 12.2512 13.1242 12.252C13.5598 12.252 13.923 12.5662 13.9897 12.9931C14.0549 13.4099 13.8042 13.8173 13.3974 13.9566C13.3594 13.9696 13.3225 13.9855 13.2851 14C9.09363 14 4.90216 14 0.710685 14C0.604402 13.9522 0.492569 13.9137 0.392959 13.8545C0.178497 13.7272 0.0743911 13.5172 0 13.2894C0 13.1801 0 13.0708 0 12.9615Z"
                fill="#A72A2F"
              />{" "}
              <path
                d="M6.12368 7.61533C6.12368 7.52753 6.12368 7.47309 6.12368 7.41865C6.12368 5.24173 6.12297 3.0648 6.12438 0.887878C6.12459 0.520586 6.29634 0.249102 6.62524 0.0923232C6.94978 -0.0623486 7.26624 -0.0205549 7.54764 0.201759C7.77145 0.378557 7.87324 0.618432 7.87303 0.906141C7.87141 3.08307 7.87218 5.25999 7.87218 7.43691C7.87218 7.48608 7.87218 7.53525 7.87218 7.61203C7.92452 7.56426 7.96041 7.53399 7.99357 7.50091C8.4867 7.00851 8.97583 6.51205 9.47338 6.02408C9.9649 5.54208 10.7648 5.75442 10.9413 6.41216C11.0254 6.72544 10.9563 7.01455 10.7274 7.24487C9.69038 8.28838 8.65101 9.32964 7.60595 10.3651C7.26153 10.7063 6.73243 10.7043 6.38773 10.3625C5.34633 9.32985 4.30935 8.29267 3.27588 7.25197C2.93181 6.90547 2.9394 6.36131 3.27525 6.02232C3.6158 5.67856 4.1638 5.67104 4.51552 6.01656C5.01266 6.50495 5.50234 7.00079 5.99541 7.49325C6.02969 7.5276 6.06565 7.56026 6.12368 7.61533Z"
                fill="#A72A2F"
              />{" "}
            </svg>
            <p>Download Invoice</p>
          </div>
        </td>
      </tr>
    ));
  }, [invoiceList?.data, downloadInvoice]);

  const paginationProps = useMemo(
    () => ({
      currentPage,
      setCurrentPage,
      totalData: invoiceList?.count || 0,
      limit: 10,
      onPageChange: handlePageChange,
    }),
    [currentPage, invoiceList?.count, handlePageChange]
  );

  return (
    <>
      <Layout>
        <div className="right-top">
          <div className="heading-top">
            <div className="common-head">
              <h2>{Icons.Invoice}Invoice</h2>
            </div>
            <div className="common-icon-side">
              <NotificationComp />
            </div>
          </div>
        </div>
        <div className="commn-table">
          <Container fluid className="px-0 mt-4">
            <div class="table-responsive position-relative">
              <Table size="sm" className="table-cmn mb-0 ">
                <thead>
                  <tr>
                    <th>Order No</th>
                    <th>Date</th>
                    <th>Customer name</th>
                    <th>Address</th>
                    <th>Quantity</th>
                    <th>Amount</th>
                    <th>Payment Status</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{memoizedRows}</tbody>
              </Table>
            </div>
            {/* Pagination */}
            <div className="d-flex justify-content-center pagination-set mt-3">
              <PaginationComponent {...paginationProps} />
            </div>
          </Container>
        </div>
      </Layout>

      <AddBankShopDetails
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <WithdrawAmount
        isModalOpenWithdraw={isModalOpenWithdraw}
        setIsModalOpenWithdraw={setIsModalOpenWithdraw}
      />
    </>
  );
};

export default Invoice;
