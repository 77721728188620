import { configureStore } from "@reduxjs/toolkit";
import userAuthReducer from "./Reducers/userSlice";
import shopAuthReducer from "./Reducers/shopSlice"
import productAuthReducer from "./Reducers/productSlice"
import orderAuthReducer from "./Reducers/orderSlice"
import socketSlice from "./Reducers/socketSlice";
import loaderSlice from "./Reducers/loaderSlice";


export const store = configureStore({
  reducer: {
    userAuthData: userAuthReducer,
    shopAuthData: shopAuthReducer,
    productAuthData: productAuthReducer,
    orderAuthData: orderAuthReducer,
    socket: socketSlice,
    loader: loaderSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
