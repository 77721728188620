import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../Components/Layout/Layout";
import Icons from "../Assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { dashboardCardAction, getShopList } from "../Redux/Actions/shopAction";
import { Encryptedid } from "../utils/Becryprt";
import DeleteModal from "../Modal/DeleteModal";
import EditShopModal from "../Modal/EditShopModal";
import { setLoader } from "../Redux/Reducers/loaderSlice";
import useCommonFetch from "../Hooks/useCustomFetch";

export default function ManageShop() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [expandedShop, setExpandedShop] = useState(null);
  const getShopDetailList = useSelector((e) => e.shopAuthData.shopListDetail);
  const [idVal, setIdVal] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalShopOpen, setIsModalShopOpen] = useState(false);
  const [editShopId, setEditShopId] = useState("");
  useCommonFetch(getShopList);

  // useEffect(() => {
  //   dispatch(getShopList())
  // }, []);

  // const toggleDescription = (index) => {
  //   setExpandedShop(expandedShop === index ? null : index);
  // };

  const handleConfirmClose = () => {
    setIsModalOpen(false);
  };

  const handleShowOpen = (val) => {
    setIsModalOpen(true);
    setIdVal(val);
  };

  const handleEditShowModal = (id) => {
    setIsModalShopOpen(true);
    setEditShopId(id);
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <div className="common-head">
            <h2>{Icons.ManageShopIcon}Manage Shop</h2>
          </div>
          <div className="common-icon-side">
            <div className="ad-shop-btn">
              <Link to="/MultiStepForm" style={{ textDecoration: "none" }}>
                <div className="manage-shop-add">
                  <div className="">{Icons.AddimageIcon}</div>
                  <p>Add Shop</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Container fluid className="px-0">
        <div className="dashboard-items">
          <Row>
            {getShopDetailList?.length > 0 &&
              getShopDetailList?.map((item, index) => (
                <Col
                  xl={6}
                  lg={12}
                  md={12}
                  sm={12}
                  className="display-table mb-4"
                >
                  <div
                    className="manage-shop-card table-cell"
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(`/ManageShopEdit/${Encryptedid(item?._id)}`);
                    }}
                  >
                    {item?.verified === 0 && (
                      <p>
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.67101 0.402431C4.73663 0.0999573 3.72088 0.520691 3.27405 1.39529L2.73686 2.44674C2.67303 2.57167 2.57143 2.67328 2.44649 2.7371L1.39505 3.27429C0.520446 3.72112 0.0997131 4.73688 0.402186 5.67125L0.765826 6.79458C0.809033 6.92805 0.809033 7.07178 0.765826 7.20525L0.402186 8.32858C0.0997131 9.26298 0.520446 10.2787 1.39505 10.7256L2.44649 11.2627C2.57143 11.3266 2.67303 11.4282 2.73686 11.5531L3.27405 12.6046C3.72088 13.4792 4.73663 13.8999 5.67101 13.5975L6.79434 13.2338C6.92781 13.1906 7.07154 13.1906 7.20501 13.2338L8.32834 13.5975C9.26274 13.8999 10.2785 13.4792 10.7253 12.6046L11.2625 11.5531C11.3263 11.4282 11.4279 11.3266 11.5529 11.2627L12.6043 10.7256C13.4789 10.2787 13.8997 9.26298 13.5972 8.32858L13.2335 7.20525C13.1903 7.07178 13.1903 6.92805 13.2335 6.79458L13.5972 5.67125C13.8997 4.73688 13.4789 3.72112 12.6043 3.27429L11.5529 2.7371C11.4279 2.67328 11.3263 2.57167 11.2625 2.44674L10.7253 1.39529C10.2785 0.520691 9.26274 0.0999573 8.32834 0.402431L7.20501 0.766071C7.07154 0.809271 6.92781 0.809277 6.79434 0.766071L5.67101 0.402431ZM3.50619 6.83812L4.449 5.89525L6.33461 7.78092L10.1059 4.00968L11.0487 4.95249L6.33461 9.66652L3.50619 6.83812Z"
                            fill="#A72A2F"
                          />
                        </svg>
                        Verification{" "}
                      </p>
                    )}
                    <div className="manage-shop-top">
                      <img
                        src={
                          item?.shop_image
                            ? `${process.env.REACT_APP_BASE_URL}public/${item?.shop_image}`
                            : require("../Assets/Images/shop-image.png")
                        }
                        className=""
                      />

                      <div className="manage-shop-details d-block">
                        <div className="">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex justify-content-between w-100">
                              <h3>{item?.name}</h3>
                              {/* <Link
                              to={`/MultiStepFormEdit/${Encryptedid(item?._id)}`}
                            >
                              {Icons.EditIcon}
                            </Link> */}
                            </div>
                          </div>
                          <div className="manage-shop-star">
                            {/* {Icons.StarIcon}
                          {Icons.StarIcon}
                          {Icons.StarIcon}
                          {Icons.StarIcon}
                          {Icons.StarIcon} */}
                          </div>
                          <h6 className="manage-shop-loca">
                            <span>{Icons.LocationIcon} </span>
                            {item?.address}
                            {item?.street ? `, ${item?.street}` : ""}
                          </h6>
                          <p>
                            {item?.description?.length > 70
                              ? `${item?.description.substring(0, 68)}...`
                              : item?.description}
                            {/* {expandedShop === index
                            ? item?.description
                            : `${item?.description.substring(0, 100)}`}
                          {item?.description.length > 100 && (
                            <button onClick={() => toggleDescription(index)}>
                              {expandedShop === index
                                ? "Read Less"
                                : "Read More"}
                            </button>
                          )} */}
                          </p>
                        </div>
                      </div>
                      <div className="shop-edit-delete">
                        <button
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditShowModal(item?._id);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="44"
                            height="36"
                            viewBox="0 0 44 36"
                            fill="none"
                          >
                            <rect
                              x="0.5"
                              y="0.5"
                              width="43"
                              height="35"
                              rx="7.5"
                              stroke="#A72A2F"
                            />
                            <path
                              d="M15.5622 26C15.2302 25.8903 14.9125 25.7548 14.6531 25.5087C14.2275 25.105 14 24.6156 14 24.0247C14.0003 20.9121 13.9995 17.7992 14.0006 14.6865C14.0008 13.5809 14.8851 12.6811 15.9876 12.6774C17.7583 12.6715 19.5287 12.675 21.2994 12.6763C21.6689 12.6766 21.9613 12.9261 21.997 13.2627C22.0339 13.6118 21.823 13.9194 21.4843 13.9995C21.4096 14.0171 21.3295 14.0155 21.2518 14.0155C19.5332 14.0163 17.8147 14.0227 16.0961 14.0115C15.6782 14.0088 15.3313 14.2863 15.3331 14.7781C15.3446 17.8282 15.3448 20.8785 15.3326 23.9286C15.3307 24.3951 15.6548 24.6979 16.0913 24.6963C19.143 24.6851 22.1949 24.6853 25.2465 24.6955C25.7177 24.6971 26.0063 24.365 26.0042 23.9389C25.9952 22.1924 26.0005 20.4455 26.0005 18.699C26.0005 18.4154 26.1292 18.2071 26.3809 18.086C26.6275 17.9672 26.8691 17.9991 27.0846 18.1672C27.2705 18.3123 27.3397 18.5139 27.3394 18.7453C27.3384 19.7151 27.3391 20.6849 27.3391 21.6547C27.3391 22.4057 27.3394 23.1563 27.3391 23.9073C27.3389 24.9492 26.8129 25.6621 25.8166 25.9726C25.8033 25.9768 25.7927 25.9904 25.781 25.9997C22.3747 26 18.9685 26 15.5622 26Z"
                              fill="#A72A2F"
                            />
                            <path
                              d="M30 11.9204C29.9644 12.0389 29.9441 12.1651 29.8893 12.274C29.8007 12.4492 29.714 12.6356 29.5826 12.7765C29.2639 13.1184 28.9202 13.4366 28.5961 13.7551C27.8185 12.9764 27.0393 12.1965 26.2375 11.3939C26.6243 11.0299 26.9864 10.6076 27.4253 10.2933C28.3716 9.61618 29.7004 10.1746 29.9649 11.3215C29.9726 11.355 29.988 11.387 29.9997 11.4197C30 11.5867 30 11.7537 30 11.9204Z"
                              fill="#A72A2F"
                            />
                            <path
                              d="M25.5096 12.1494C26.3003 12.941 27.0795 13.7213 27.8882 14.5308C27.8491 14.5585 27.7919 14.588 27.7483 14.6314C26.0944 16.2851 24.4391 17.9376 22.7924 19.5987C22.6094 19.7833 22.4112 19.8826 22.1619 19.9287C21.4982 20.0512 20.8371 20.1867 20.1754 20.3206C19.8166 20.3931 19.629 20.2096 19.7008 19.8544C19.8448 19.141 19.9818 18.4263 20.1323 17.7145C20.1579 17.5939 20.2106 17.4583 20.2946 17.3734C22.0087 15.647 23.7291 13.9274 25.4485 12.2061C25.4708 12.1842 25.4942 12.164 25.5096 12.1494Z"
                              fill="#A72A2F"
                            />
                          </svg>
                        </button>
                        <button
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleShowOpen(item?._id);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="44"
                            height="36"
                            viewBox="0 0 44 36"
                            fill="none"
                          >
                            <rect
                              width="44"
                              height="36"
                              rx="8"
                              fill="#A72A2F"
                              fill-opacity="0.2"
                            />
                            <path
                              d="M16.3965 14.006C15.8931 14.006 15.4192 14.0077 14.9454 14.0048C14.8271 14.0041 14.7054 14.0037 14.5914 13.9769C14.2248 13.8904 13.9753 13.5316 14.0019 13.1482C14.029 12.7592 14.3154 12.4556 14.7 12.4126C14.7866 12.4029 14.8745 12.4012 14.9619 12.4012C19.6558 12.4007 24.3496 12.4006 29.0435 12.4019C29.1679 12.4019 29.2964 12.4025 29.4159 12.4319C29.7835 12.5222 30.0268 12.8801 29.9976 13.2655C29.9682 13.6533 29.6809 13.9715 29.293 13.9908C28.8129 14.0147 28.331 14.0027 27.8499 14.006C27.7766 14.0065 27.7033 14.0061 27.6043 14.0061C27.6043 14.1061 27.6043 14.185 27.6043 14.264C27.6043 17.3366 27.6026 20.4091 27.6053 23.4817C27.6062 24.4571 27.204 25.2016 26.3596 25.6974C25.99 25.9143 25.5828 25.9976 25.1571 25.9976C23.0508 25.9978 20.9445 26.0043 18.8382 25.9951C17.6126 25.9897 16.6726 25.2071 16.4377 24.0163C16.4006 23.8283 16.3984 23.6314 16.3982 23.4386C16.3959 20.3848 16.3966 17.331 16.3966 14.2772C16.3965 14.1969 16.3965 14.1167 16.3965 14.006ZM21.1967 19.1995C21.1951 19.1995 21.1935 19.1995 21.1919 19.1995C21.1919 18.5192 21.2059 17.8386 21.1869 17.1587C21.1744 16.7135 20.8198 16.4013 20.391 16.4071C19.9575 16.4128 19.6051 16.7493 19.6027 17.1949C19.5951 18.5306 19.5961 19.8664 19.6023 21.2021C19.6041 21.5968 19.8941 21.9223 20.2596 21.9773C20.7853 22.0565 21.1888 21.7048 21.195 21.1469C21.2023 20.4979 21.1967 19.8487 21.1967 19.1995ZM24.4013 19.2182C24.4013 18.5628 24.4036 17.9074 24.4006 17.252C24.3983 16.7662 24.0654 16.4138 23.6106 16.4071C23.1559 16.4003 22.8089 16.7434 22.8064 17.2323C22.7998 18.543 22.7972 19.8539 22.8121 21.1646C22.8142 21.3445 22.8906 21.5466 22.993 21.6974C23.1763 21.9675 23.541 22.0544 23.8486 21.9521C24.1784 21.8425 24.3965 21.5493 24.3995 21.1844C24.405 20.5291 24.4011 19.8736 24.4013 19.2182Z"
                              fill="#A72A2F"
                            />
                            <path
                              d="M21.9843 11.5986C21.4287 11.5986 20.8726 11.6115 20.3176 11.5939C19.9732 11.5829 19.7015 11.3245 19.6177 10.9888C19.5389 10.6731 19.6689 10.3128 19.9577 10.1439C20.0916 10.0657 20.2606 10.011 20.4144 10.0093C21.4697 9.9978 22.5251 9.99739 23.5804 10.0057C24.0603 10.0095 24.3976 10.3493 24.3999 10.7975C24.4022 11.2566 24.0655 11.5961 23.5768 11.605C23.0462 11.6146 22.5153 11.6072 21.9844 11.6072C21.9843 11.6042 21.9843 11.6015 21.9843 11.5986Z"
                              fill="#A72A2F"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="manage-shop-bottom">
                      <Row>
                        <Col xxl={4} xl={4} lg={4} className="p-0">
                          <div className="dash-inner-boxes">
                            <div className="icon-part">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                viewBox="0 0 30 30"
                                fill="none"
                              >
                                <path
                                  d="M0.132435 8.16321C4.71496 10.1948 9.19738 12.1821 13.7301 14.1917C13.7301 19.3683 13.7301 24.6567 13.7301 29.996C13.4389 29.8847 13.1704 29.7961 12.9133 29.6816C9.61526 28.212 6.30865 26.7613 3.02577 25.2581C1.05742 24.3568 0.0217398 22.7756 0.0106411 20.5869C-0.00790548 16.9023 0.00151382 13.2176 0.0109331 9.53294C0.0121014 9.09491 0.0867257 8.65711 0.132435 8.16321Z"
                                  fill="#A72A2F"
                                />
                                <path
                                  d="M16.2446 30C16.2446 29.8105 16.2446 29.6935 16.2446 29.5766C16.2446 24.5336 16.2475 19.4907 16.2373 14.4477C16.2367 14.1453 16.3364 14.0555 16.6127 13.9446C18.0572 13.3653 19.4844 12.7421 20.9176 12.1343C21.0811 12.0649 21.2449 11.9961 21.4677 11.902C21.4677 12.9359 21.4605 13.9099 21.4699 14.8836C21.478 15.7187 22.1975 16.2813 23.0072 16.1015C23.578 15.9748 23.9493 15.4521 23.9527 14.7424C23.9585 13.5535 23.9653 12.3644 23.9468 11.1758C23.9422 10.881 24.0367 10.7419 24.3024 10.6255C26.1214 9.82847 27.933 9.01417 29.8227 8.17164C29.8782 8.55276 29.9724 8.90197 29.9733 9.25148C29.9837 13.1447 30.0367 17.0392 29.9561 20.9307C29.9142 22.9567 28.8044 24.4176 26.9708 25.2575C24.2919 26.4846 21.5943 27.6704 18.9042 28.8726C18.2286 29.1746 17.5523 29.4749 16.8737 29.7699C16.6864 29.8516 16.4895 29.9113 16.2446 30Z"
                                  fill="#A72A2F"
                                />
                                <path
                                  d="M9.17497 2.16192C9.32443 2.08976 9.43725 2.03183 9.55276 1.97998C10.6784 1.47442 11.8034 0.967388 12.9307 0.465784C14.2584 -0.124921 15.6069 -0.168335 16.9368 0.419143C20.3333 1.91941 23.7229 3.43544 27.1038 4.97091C27.6126 5.20198 28.0593 5.57129 28.6325 5.93943C28.2665 6.1037 28.0343 6.20849 27.8016 6.31233C26.2128 7.02154 24.6261 7.73538 23.0319 8.43198C22.8759 8.5001 22.6417 8.52995 22.4974 8.46226C18.1574 6.42695 13.8241 4.37727 9.49004 2.32905C9.39767 2.28535 9.30925 2.23342 9.17497 2.16192Z"
                                  fill="#A72A2F"
                                />
                                <path
                                  d="M19.6936 9.95057C18.4558 10.4891 17.2859 11.0467 16.0793 11.5068C15.2114 11.8378 14.3102 11.7307 13.4597 11.3512C9.53582 9.60018 5.61454 7.84354 1.69275 6.088C1.62492 6.05764 1.56227 6.01548 1.38637 5.9164C1.95277 5.55772 2.42885 5.19509 2.95545 4.93622C3.91016 4.46703 4.89174 4.05174 5.87105 3.6352C6.01461 3.57419 6.23337 3.57412 6.37356 3.6399C10.7209 5.68027 15.0628 7.73222 19.4051 9.78352C19.4844 9.82092 19.5577 9.87137 19.6936 9.95057Z"
                                  fill="#A72A2F"
                                />
                              </svg>
                            </div>
                            <div className="text-part">
                              <h2>{item?.total_products || "0"}</h2>
                              <p>Total Products</p>
                            </div>
                          </div>
                        </Col>
                        <Col xxl={4} xl={4} lg={4} className="p-0">
                          <div className="dash-inner-boxes">
                            <div className="icon-part">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="28"
                                height="30"
                                viewBox="0 0 28 30"
                                fill="none"
                              >
                                <path
                                  d="M26.1808 30C18.0637 30 9.94663 30 1.82949 30C1.80434 29.9835 1.78139 29.9573 1.75365 29.9518C0.687268 29.739 -0.00437951 28.818 2.08745e-05 27.6903C0.0238301 21.6374 0.0112628 15.5843 0.0112628 9.53134C0.0112628 9.41916 0.0112628 9.30692 0.0112628 9.19569C9.36405 9.19569 18.6671 9.19569 27.9991 9.19569C27.9991 9.32995 27.9991 9.44528 27.9991 9.56061C27.9991 13.456 27.9991 17.3514 27.9991 21.2468C27.9991 23.4435 27.9972 25.6401 28 27.8368C28.0009 28.5813 27.7059 29.1932 27.0492 29.6179C26.7898 29.7857 26.472 29.8749 26.1808 30ZM12.7726 19.7981C12.1879 19.2478 11.6741 18.7616 11.157 18.2784C10.9043 18.0424 10.6032 17.9289 10.2459 18.0153C9.50816 18.1935 9.28893 18.9869 9.84126 19.5159C10.5272 20.1728 11.2286 20.8156 11.924 21.4639C12.5066 22.0072 12.9802 22.0073 13.5644 21.4626C15.0808 20.0486 16.5967 18.6342 18.1112 17.2185C18.2142 17.1223 18.3192 17.0231 18.3973 16.9097C18.737 16.4171 18.4532 15.7381 17.8477 15.602C17.4565 15.5141 17.14 15.6484 16.8603 15.9114C15.6524 17.0476 14.4361 18.1758 13.2264 19.3101C13.0726 19.4543 12.9395 19.6175 12.7726 19.7981Z"
                                  fill="#A72A2F"
                                />
                                <path
                                  d="M13.0896 0.00431999C13.0896 2.48493 13.0896 4.93884 13.0896 7.41337C8.96251 7.41337 4.85114 7.41337 0.704481 7.41337C0.764593 7.30596 0.812292 7.21301 0.867297 7.12394C2.12801 5.08044 3.39351 3.03943 4.64864 0.992985C5.0607 0.321183 5.65452 -0.00529082 6.49138 6.48218e-05C8.57174 0.0132705 10.6523 0.00439335 12.7329 0.00439335C12.8455 0.00431999 12.9582 0.00431999 13.0896 0.00431999Z"
                                  fill="#A72A2F"
                                />
                                <path
                                  d="M27.3159 7.4174C23.192 7.4174 19.1005 7.4174 14.988 7.4174C14.988 4.94625 14.988 2.49176 14.988 0.0174523C15.1002 0.0127569 15.2016 0.0049069 15.3032 0.00483354C17.4156 0.00409989 19.5282 0.00527373 21.6407 0.00380643C22.3622 0.00329288 22.9197 0.295138 23.2773 0.866284C24.6052 2.98697 25.9167 5.11653 27.2335 7.24331C27.2631 7.29092 27.2818 7.3444 27.3159 7.4174Z"
                                  fill="#A72A2F"
                                />
                              </svg>
                            </div>
                            <div className="text-part">
                              <h2>{item?.total_orders || "0"}</h2>
                              <p>Total Orders</p>
                            </div>
                          </div>
                        </Col>

                        <Col xxl={4} xl={4} lg={4} className="p-0">
                          <div className="dash-inner-boxes right-line">
                            <div className="icon-part">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                viewBox="0 0 30 30"
                                fill="none"
                              >
                                <path
                                  d="M0 6.40695C0.115758 5.94373 0.201656 5.47021 0.352386 5.01875C1.29072 2.20483 4.05091 0.383518 6.98658 0.624505C9.99485 0.87147 12.4857 3.27804 12.8495 6.28903C13.2753 9.81272 10.9665 12.9435 7.4989 13.5446C3.93699 14.162 0.51048 11.6027 0.0564473 7.98728C0.0492891 7.93224 0.0192248 7.87987 0 7.82607C0 7.35296 0 6.88006 0 6.40695ZM6.08915 3.32875C6.00161 3.35369 5.91817 3.37555 5.83595 3.4009C4.78043 3.726 4.15787 4.759 4.40227 5.84066C4.58389 6.64423 5.10745 7.15259 5.87747 7.41769C5.96419 7.44758 6.08833 7.55004 6.08956 7.62075C6.10408 8.48554 6.09917 9.35053 6.09917 10.2194C5.5302 10.09 5.09314 9.58039 5.04733 9.00998C5.02115 8.68303 4.89946 8.52512 4.68471 8.53934C4.44931 8.5548 4.30881 8.78754 4.35298 9.09841C4.48326 10.0185 4.99558 10.6165 5.8697 10.895C6.01347 10.9408 6.10796 10.9704 6.10919 11.1556C6.11062 11.3584 6.24867 11.4842 6.45155 11.4846C6.65444 11.485 6.78758 11.3607 6.79658 11.157C6.80333 11.0016 6.85691 10.9438 7.00478 10.9004C7.93248 10.6274 8.50697 9.84735 8.50533 8.88629C8.50349 7.9244 7.93698 7.16124 6.99762 6.8782C6.84566 6.83244 6.80026 6.76729 6.80251 6.61392C6.81069 6.05279 6.80578 5.49145 6.80578 4.93011C6.80578 4.64171 6.80578 4.35331 6.80578 4.03254C7.42281 4.28157 7.75066 4.6883 7.80015 5.31086C7.82163 5.58092 7.97358 5.76026 8.17504 5.75284C8.39878 5.7446 8.52395 5.55205 8.50043 5.2519C8.42844 4.33558 7.88176 3.66044 7.00314 3.37884C6.92215 3.35287 6.82664 3.24052 6.81396 3.15662C6.78022 2.93336 6.68368 2.75834 6.46158 2.75484C6.22167 2.75092 6.10714 2.92944 6.09958 3.16899C6.09753 3.21784 6.09324 3.26608 6.08915 3.32875Z"
                                  fill="#A72A2F"
                                />
                                <path
                                  d="M23.7684 12.9095C23.2088 12.9095 22.7012 12.9208 22.1948 12.9014C22.0688 12.8967 21.8846 12.8225 21.839 12.7264C21.7856 12.6139 21.8068 12.4112 21.8776 12.3009C23.0722 10.4427 24.2783 8.59191 25.4853 6.74173C25.6839 6.43746 25.9257 6.43828 26.1343 6.7407C27.4009 8.575 28.6676 10.4089 29.9215 12.2519C29.9997 12.3669 30.0259 12.5842 29.9706 12.7048C29.923 12.8091 29.7273 12.8944 29.5933 12.9002C29.0877 12.9227 28.5805 12.9093 28.0422 12.9093C28.0422 18.1353 28.0422 23.3405 28.0422 28.5614C26.6071 28.5614 25.2037 28.5614 23.7684 28.5614C23.7684 23.3577 23.7684 18.1524 23.7684 12.9095Z"
                                  fill="#A72A2F"
                                />
                                <path
                                  d="M18.6607 3.45966C18.0648 3.20444 17.5179 2.97047 16.9712 2.73566C16.8816 2.69711 16.7706 2.67629 16.7098 2.60971C16.6168 2.50787 16.4967 2.36955 16.5039 2.2541C16.5106 2.14752 16.6507 2.00528 16.7648 1.95643C17.9228 1.46147 19.0874 0.982584 20.25 0.499168C20.5926 0.35672 20.9346 0.212829 21.2784 0.0738851C21.622 -0.0650585 21.7371 -0.019706 21.8721 0.31013C22.4822 1.79975 23.0894 3.29061 23.7009 4.77983C23.7755 4.96165 23.8347 5.13893 23.6768 5.29932C23.515 5.46362 23.3342 5.43414 23.1381 5.34446C22.5787 5.08905 22.0138 4.846 21.4213 4.58502C21.3465 4.81508 21.2757 5.03524 21.2033 5.25458C18.791 12.5668 13.9588 17.6715 7.11051 20.9296C6.39756 21.269 5.66886 21.5842 4.92441 21.8423C4.5892 21.9585 4.18814 21.9684 3.83228 21.9177C3.19193 21.8264 2.7424 21.2978 2.69842 20.6998C2.64545 19.9779 3.02075 19.3895 3.6883 19.1263C4.32333 18.8758 4.96081 18.6294 5.58582 18.3549C7.27311 17.6136 8.86018 16.7013 10.3335 15.5799C13.8402 12.9111 16.4617 9.56822 18.0335 5.41723C18.2721 4.78663 18.4472 4.13149 18.6607 3.45966Z"
                                  fill="#A72A2F"
                                />
                                <path
                                  d="M22.2298 28.5703C20.7865 28.5703 19.3898 28.5703 17.95 28.5703C17.95 28.46 17.95 28.3565 17.95 28.2528C17.95 24.8932 17.95 21.5337 17.9502 18.1741C17.9502 17.7125 18.0196 17.642 18.4734 17.6418C19.5682 17.6414 20.6628 17.6408 21.7576 17.6422C22.114 17.6426 22.2294 17.7591 22.2294 18.1199C22.2302 21.5287 22.23 24.9376 22.2298 28.3464C22.2298 28.413 22.2298 28.4796 22.2298 28.5703Z"
                                  fill="#A72A2F"
                                />
                                <path
                                  d="M16.354 28.5635C14.9207 28.5635 13.5175 28.5635 12.0844 28.5635C12.0844 28.4545 12.0844 28.359 12.0844 28.2634C12.0844 26.057 12.0842 23.8506 12.0846 21.6442C12.0846 21.2102 12.1644 21.1302 12.5959 21.13C13.7099 21.1296 14.824 21.129 15.938 21.1304C16.2728 21.1308 16.3632 21.2162 16.3634 21.5405C16.365 23.8256 16.3642 26.1108 16.3638 28.3959C16.3636 28.4441 16.3583 28.492 16.354 28.5635Z"
                                  fill="#A72A2F"
                                />
                                <path
                                  d="M10.5571 28.5633C9.11337 28.5633 7.71016 28.5633 6.2773 28.5633C6.2773 28.4639 6.2773 28.378 6.2773 28.2922C6.2773 26.8343 6.27689 25.3762 6.27771 23.9184C6.27792 23.5255 6.3677 23.4364 6.76181 23.436C7.8564 23.4352 8.95098 23.4352 10.0456 23.436C10.4464 23.4362 10.5565 23.5426 10.5567 23.9336C10.5577 25.3915 10.5571 26.8496 10.5571 28.3075C10.5571 28.385 10.5571 28.4623 10.5571 28.5633Z"
                                  fill="#A72A2F"
                                />
                                <path
                                  d="M16.8082 29.2891C20.8249 29.2891 24.8417 29.2891 28.8585 29.2891C28.9464 29.2891 29.0348 29.2844 29.1223 29.2916C29.3145 29.3077 29.4301 29.4151 29.4411 29.6101C29.4528 29.8175 29.3383 29.9441 29.1446 29.989C29.0607 30.0084 28.9695 29.9962 28.8816 29.9962C20.8286 29.9964 12.7755 29.9964 4.72255 29.9962C4.63461 29.9962 4.54298 30.0084 4.45974 29.9878C4.26688 29.9399 4.15419 29.8111 4.16973 29.6039C4.18426 29.4093 4.30185 29.3042 4.49451 29.2912C4.58205 29.2852 4.6704 29.2891 4.75834 29.2891C8.77489 29.2891 12.7916 29.2891 16.8082 29.2891Z"
                                  fill="#A72A2F"
                                />
                                <path
                                  d="M6.07872 6.7304C5.49952 6.57888 5.06655 6.05423 5.0463 5.48403C5.02278 4.81858 5.40687 4.28177 6.07872 4.06367C6.07872 4.95629 6.07872 5.84252 6.07872 6.7304Z"
                                  fill="#A72A2F"
                                />
                                <path
                                  d="M6.81785 10.23C6.81785 9.32662 6.81785 8.4439 6.81785 7.55787C7.38416 7.70836 7.78338 8.22311 7.79974 8.81104C7.81897 9.50308 7.46372 10.0263 6.81785 10.23Z"
                                  fill="#A72A2F"
                                />
                              </svg>
                            </div>
                            <div className="text-part">
                              <h2>${item?.total_revenue || "0"} </h2>
                              <p>Total Revenue</p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              ))}
            {/* 
            <Col lg={3}>
              <Link to="/MultiStepForm" style={{ textDecoration: "none" }}>
                <div className="manage-shop-add">
                  <div className="">{Icons.AddimageIcon}</div>
                  <p>Add New</p>
                </div>
              </Link>
            </Col> */}
          </Row>
        </div>
      </Container>

      {/* Delete Modal */}
      <DeleteModal
        isModalOpen={isModalOpen}
        handleConfirmClose={handleConfirmClose}
        id={idVal}
        page={"shopList"}
      />

      {/* Edit Shop Modal */}
      <EditShopModal
        isModalShopOpen={isModalShopOpen}
        setIsModalShopOpen={setIsModalShopOpen}
        id={editShopId}
      />
    </Layout>
  );
}
