import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getShopList, shopDeleteAction } from "../Redux/Actions/shopAction";
import toast from "react-hot-toast";
import { productDeleteAction } from "../Redux/Actions/productAction";

const DeleteModal = ({ isModalOpen, handleConfirmClose, id, page }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDelete = async () => {
    try {
      let res;
      if (page == "product") {
        res = await dispatch(productDeleteAction(id));
      } else {
        res = await dispatch(shopDeleteAction(id));
      }
      if (res?.payload?.status == 200) {
        toast.success(res?.payload?.message);
        if (page === "shopList") {
          await dispatch(getShopList());
        } else if (page === "shopDetails" || page === "product") {
          navigate(`/ManageShop`);
        }
        handleConfirmClose();
      } else {
        toast.error(res?.payload?.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Modal
      show={isModalOpen}
      onHide={handleConfirmClose}
      className="comn-modal-set"
    >
      <Modal.Header className="border-none" closeButton>
        <Modal.Title className="text-center modal-title ">
          Delete {page === "product" ? `Product` : `Shop`}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="text-center suspend-text">
          {" "}
          Are you sure you want to Delete this{" "}
          {page === "product" ? `Product` : `Shop`} ?
        </p>
      </Modal.Body>
      <Modal.Footer className="border-none justify-content-center modal-footer">
        <Button
          onClick={() => handleDelete()}
          variant="danger"
          className="modal-close-btn "
        >
          Yes
        </Button>
        <Button
          variant="secondary"
          onClick={handleConfirmClose}
          className="modal-f-btn "
        >
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
