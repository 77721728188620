import { createAsyncThunk } from "@reduxjs/toolkit";
import adminapi from "../../Service/adminApi";
import api from "../../Service/api";


// get category action
export const getCategoryAction = createAsyncThunk("getCategoryAction/admin/category", async () => {
    const response = await adminapi.get("/categories");
    return response.data;
  }
);

// get subcategory 
export const getSubCategoryAction = createAsyncThunk("getSubCategoryAction/admin/category", async (id) => {
  const response = await adminapi.get(`/subcategories?category_id=${id}`);
  return response.data;
}
);

// get category action
export const getFlavourAction = createAsyncThunk("getFlavourAction/flavor", async () => {
    const response = await adminapi.get("/flavors");
    return response.data;
  }
);


// add product action
export const createProductAction = createAsyncThunk("createProductAction/shop/product", async (data) => {
    const response = await api.post("/shop/product", data);
    return response.data;
  }
);

export const productDetailById = createAsyncThunk('productDetailById/shop/id/product',async ({ id, categoryId }, thunkAPI) => {
    const response = await api.get(`/shop/${id}/products`, {
      params: {
        category_id: categoryId,
      },
    });
    return response.data;
  }
);


// particular product detail by id 
export const particularProductDetailById = createAsyncThunk('particularProductDetailById/shop/product/id',  async (id) => {
  const response = await api.get(`/shop/product/${id}`);
  return response.data;
}
);

// particular product detail by id 
export const editProductDetail = createAsyncThunk('editProductDetail/shop/product/id',async ({ id, data }, thunkAPI) => {
    try {
      const response = await api.put(`/shop/product/${id}`, data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// arrange product categories in shop  
export const arrangeProductOrder = createAsyncThunk('arrangeProductOrder/shop/arrange-categories',  async (data) => {
  const response = await api.post(`/shop/arrange-categories`, data);
  return response.data;
}
);


// selected categories
export const particulatCategoryByShopId = createAsyncThunk('particulatCategoryByShopIdshop/id/categories',  async (id) => {
  const response = await api.get(`shop/${id}/categories`);
  return response.data;
}
);

// arrage products within category
export const arrangeProductWithinCategory = createAsyncThunk('arrangeProductWithinCategory/shop/arrange-products',  async (data) => {
  const response = await api.post(`/shop/arrange-products`, data);
  return response.data;
}
)

// action for product filter with respect to subcategory

export const arrangeproductBySubcategoryId = createAsyncThunk('arrangeproductBySubcategoryId/shop/id/product',async ({ id, shopId }, thunkAPI) => {
  const response = await api.get(`/shop/subcategory/${id}/products`, {
    params: {
      shop_id: shopId,
    },
  });
  return response.data;
}
);

// product delte
export const productDeleteAction = createAsyncThunk('productDeleteAction/delete', async (id) => {
  const response = await api.delete(`/shop/product/${id}`);
  return response.data;
})